import { Box, Button, CircularProgress } from "@mui/material";
import styles from "./style";
import SearchTextField from "../../components/searchTextField/SearchTextField";
import DataTable from "../../components/DataTable";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Actions } from "../../pages/actions";
import { useDispatch } from "react-redux";

export default function Funds({
  onMount,
  loading,
  data,
  changeTab,
  filter,
  submitSearch,
  clearSearch,
  onDeleteFund,
  submitSuccess,
}) {
  let filterSearch = "";
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [sortedTable, setsortedTable] = useState(data);
  const role = sessionStorage.getItem("userRole");
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    reset();
    console.log("render");
  }, []);
  const reset = () => {
    dispatch(Actions.RESET_FUND_DATA());
  };
  const handleDeleteFund = (id) => {
    onDeleteFund(id);
  };

  const onChangePagination = (newPage) => {
    onMount({ page: newPage, filter: filter });
    setPage(newPage);
  };
  useEffect(() => {
    setsortedTable(data);
  }, [data]);

  useEffect(() => {
    console.log("in useEfect - submitSuccess");
    onMount({ page: 0, filter: filter });
  }, [submitSuccess]);

  useEffect(() => {
    onMount({ page: 0, filter: filter });
    changeTab(3);
  }, []);

  useEffect(() => {
    onMount({ page: 0, filter: filter });
  }, [filter]);

  const handleAction = (row) => {
    console.log("in handel action");
    if (row.hasOwnProperty("fundType")) {
      if (row.fundType === "mosad")
        navigate(`${location.pathname}/mosad/${row.id}`);
      if (row.fundType === "private")
        navigate(`${location.pathname}/${row.id}`);
    }
  };

  const showData = (data) => {
    let showtable = [];
    let types = [];
    let expensesSum = 0;
    if (data.length > 0) {
      data.map((item, index) => {
        expensesSum = 0;
        item.expenses.forEach((expense) => {
          expensesSum += expense.amount;
        });
        showtable.push({ ...item });
        showtable[index] = {
          ...showtable[index],
          nameLabel:
            item.fundType === "mosad"
              ? item.mosadName
              : item.firstName + " " + item.lastName,
          sumOfExpenses: expensesSum,
          fundTypeLabel: item.fundType === "mosad" ? "מוסדית" : "פרטית",
          isActiveLabel: item.isActive === true ? "פעילה" : "חסומה",
        };
      });
    }
    return showtable;
  };

  let headers = [
    {
      name: "שם בעל הקרן",
      width: "15%",
      table: "nameLabel",
    },
    {
      name: "סך הוצאות לקרן",
      width: "10%",
      table: "sumOfExpenses",
    },
    {
      name: "סוג קרן",
      width: "10%",
      table: "fundTypeLabel",
    },
    {
      name: "סטטוס קרן",
      width: "10%",
      table: "isActiveLabel",
    },
    {
      name: "  ",
      width: "10%",
      table: "Action",
    },
    {
      name: "מחק",
      width: "10%",
      table: "deletLabel",
    },
  ];

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box sx={styles.wrap}>
        <Box sx={styles.actions}>
          {(role === "admin" || role === "officer") && (
            <Box>
              <Button
                variant="contained"
                disableRipple
                onClick={() => {
                  navigate("/mosad");
                }}
                sx={styles.createButton}
              >
                צור קרן מוסדית
              </Button>
              <Button
                variant="contained"
                disableRipple
                onClick={() => {
                  navigate("/");
                }}
                sx={styles.createButton}
              >
                צור קרן פרטית
              </Button>
            </Box>
          )}

          {
            <SearchTextField
              onEmptySearch={() => {
                clearSearch();
              }}
              onSearch={() => {
                submitSearch(filterSearch);
              }}
              width="30%"
              setSearch={(item) => {
                filterSearch = item;
              }}
            />
          }
        </Box>
        <Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loading ? (
              <CircularProgress
                color="primary"
                sx={{ position: "absolute", top: "50%", left: "50%" }}
              />
            ) : (
              <DataTable
                fdata={sortedTable}
                showData={showData}
                headers={headers}
                onChange={onChangePagination}
                page={page}
                handleDelete={handleDeleteFund}
                disableClick={true}
                handleActionClick={handleAction}
                buttonLable={"צפיה"}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
