import { ActionsTypes } from "./actions";
import { ActionTypes as PageActionsTypes } from "../../pages/actions";

const referencesReducer = (
  state = {
    data: [],
    loading: true,
    saveSuccess: false,
    isSaving: false,
  },
  action
) => {
  let newState;
  let { payload, type } = action;
  switch (type) {
    case ActionsTypes.GET_REFERENCES_SUCCESS:
      newState = {
        ...state,
        loading: false,
        data: payload,
      };
      break;
    case ActionsTypes.SAVE_FILE_SUCCESS:
      newState = {
        ...state,
        // loading: true,
        isSaving: false,
        saveSuccess: true,
      };
      break;
    default:
      newState = state;
  }
  return newState;
};
export default referencesReducer;
