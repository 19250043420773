import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableFooter,
  TableContainer,
  Button,
  CircularProgress,
  Box,
  TextField,
} from "@mui/material";
import styles from "./style";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { styled } from "@mui/material/styles";
import Link from "@mui/material/Link";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function DataTable({
  fdata,
  headers,
  showData,
  onChange,
  page,
  path,
  onUpdate,
  isSubmitting,
  error,
  submitSuccess,
  disableClick,
  setOpen,
  setAmount,
  setDate,
  setupdate,
  setExpenseId,
  handleDelete,
  handleActionClick,
  buttonLable,
  saveFile,
  setIsSaving,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedRow, setSelectedRow] = useState();
  const [openRefDialog, setOpenRefDialog] = React.useState(false);
  const [link, setLink] = useState("");

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleClose = () => {
    setOpenRefDialog(false);
    window.location.reload();
  };
  useEffect(() => {
    console.log("openRefDialog has changed:", openRefDialog);
  }, [openRefDialog]);
  let count = 0;
  let emptyRows = 0;
  let rowsPerPage = 10;
  const handleChangePage = (event, newPage) => {
    onChange(newPage);
  };
  let dataView = [];
  let data = fdata ? (fdata.length > 0 ? fdata : []) : [];
  if (data[0] && data[0].length > 0) {
    dataView = showData(data[0]);

    count = data[1];
    emptyRows = rowsPerPage - dataView.length;
  } else {
    count = 0;

    emptyRows = 10;
  }
  console.log("show data in dT TABLE", dataView);
  const handleclick = (row) => {
    if (row.hasOwnProperty("fundType")) {
      if (row.fundType === "mosad")
        navigate(`${location.pathname}/mosad/${row.id}`);
      if (row.fundType === "private")
        navigate(`${location.pathname}/${row.id}`);
    } else if (row.hasOwnProperty("status") && row.status === "finished") {
    }
    //   && row.status !== "finished"   its was in the if
    else if (row.hasOwnProperty("amount")) {
      setAmount(row.amount);
      setupdate(true);
      console.log(row.updatedAt);
      console.log(row.createdAt);
      setDate(row.createdAt);
      setOpen(true);
      setExpenseId(row.id);
    }
  };
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  useEffect(() => {
    console.log("selectedFile updated", selectedFile);
  }, [selectedFile]);

  // const handleselectedFile = (event, row) => {
  //   const formData = new FormData();
  //   const file = URL.createObjectURL(event.target.files[0]);
  //   console.log(116);
  //   console.log(file);
  //   console.log(118);

  //   setSelectedFile({
  //     url: file,
  //     file: event.target.files[0],
  //     name: event.target.files[0].name,
  //   });
  //   formData.append("file", selectedFile);
  //   formData.append("rowId", row);
  //   saveFile(formData);
  // };
  // const handleselectedFile = (event, row) => {
  //   const formData = new FormData();
  //   const file = event.target.files[0];
  //   console.log(116);
  //   console.log(file);
  //   console.log(118);
  //   setSelectedFile({
  //     url: URL.createObjectURL(file),
  //     file: file,
  //     name: file.name,
  //   });
  //   formData.append("file", file);
  //   // formData.append("rowId", row); // Remove this line
  //   saveFile({ formData, rowId: row }); // Pass rowId separately from formData
  // };

  const handleselectedFile = (event, row) => {
    // const formData = new FormData();
    if (event.target.files.length > 0) {
      console.log("event.target.files", event.target.files);
      const file = URL.createObjectURL(event.target.files[0]);
      console.log("file1", file);
      const selectedFile = {
        url: file,
        file: event.target.files[0],
        name: event.target.files[0].name,
      };
      saveFile({ file: selectedFile, rowId: row });
    }
  };
  console.log("selectedFile", selectedFile);

  const handleClickOpenDialog = (id) => {
    setOpenDialog(true);
    setSelectedId(id);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  //     const handleActionClick = (row) => {
  //    setSelectedId(row.id);
  //   onUpdate(row.id);
  //     };
  const check = (row, header) => {
    let date = new Date(row[header.table]);
    let ddate =
      date.getDate() - 1 + "." + date.getMonth() + "." + date.getFullYear();
    console.log(ddate);
    return ddate;
  };

  return (
    <React.Fragment>
      <TableContainer>
        <Table sx={{ width: "100%", mt: "2%" }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#D4D4D4", borderBottom: "none" }}>
              {headers.map((item, i) => (
                <TableCell
                  key={i}
                  sx={{
                    minWidth: { ...item.width },
                    borderRadius:
                      i == 0
                        ? "8px 0px 0px 0px "
                        : i + 1 === headers.length
                        ? " 0px 8px 0px 0px  "
                        : {},
                    ...styles.tabelHeader,
                  }}
                >
                  {item.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataView &&
              dataView.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => {
                    if (disableClick) return;
                    handleclick(row);
                  }}
                >
                  {headers.map((header, i) => (
                    <TableCell key={i} align="left" sx={styles.tabelCell}>
                      {header.table === "Action" ? (
                        <Button
                          variant="contained"
                          onClick={() => {
                            handleActionClick(row);
                          }}
                        >
                          {/* {isSubmitting && row.id === selectedId ? (
                          <CircularProgress
                            size={20}
                            sx={{ color: "#333333" }}
                          />
                        ) : (
                          "עדכן"
                        )} */}
                          {buttonLable}
                        </Button>
                      ) : header.table === "exportDte" ? (
                        <label>ttttt</label>
                      ) : header.table === "deletLabel" ? (
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            handleClickOpenDialog(row.id);
                          }}
                        >
                          {isSubmitting && row.id === selectedId ? (
                            <CircularProgress
                              size={20}
                              sx={{ color: "#333333" }}
                            />
                          ) : (
                            <DeleteIcon />
                          )}
                        </IconButton>
                      ) : header.table === "refernce" ? (
                        <Button
                          component="label"
                          role={undefined}
                          tabIndex={-1}
                          variant="contained"
                          color="primary"
                          sx={{ width: { md: "9vw" } }}
                          endIcon={<UploadFileIcon />}
                        >
                          בחר קובץ
                          <VisuallyHiddenInput
                            type="file"
                            onChange={(e) => {
                              setIsSaving(true);
                              handleselectedFile(e, row.id);
                            }}
                          />
                        </Button>
                      ) : header.table === "showFile" ? (
                        <Button
                          component="label"
                          variant="contained"
                          disabled={row.confirmationDocumentUrl ? false : true}
                          color="primary"
                          sx={{
                            width: { md: "10vw" },
                            height: { md: "3.5vw" },
                          }}
                          onClick={() => {
                            setOpenRefDialog(true);
                            setLink(row.confirmationDocumentUrl);
                          }}
                        >
                          {row.confirmationDocumentUrl
                            ? row.label
                            : "הצגת קובץ"}
                        </Button>
                      ) : header.table === "createdAt" ||
                        header.table === "updtedAt" ? (
                        check(row, header)
                      ) : (
                        row[header.table]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 34 * emptyRows }}>
                {headers.map((item, i) => (
                  <TableCell key={i} sx={styles.cell} />
                ))}
              </TableRow>
            )}
          </TableBody>
          <TableFooter sx={{ height: "auto !important", p: 1 }}>
            <TableRow>
              <TablePagination
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[]}
                count={count}
                page={count <= 0 ? 0 : page}
                onPageChange={handleChangePage}
                align="center"
                sx={styles.pagination}
                labelDisplayedRows={({ page }) => {
                  return `${
                    page == 0
                      ? dataView.length < 10
                        ? dataView.length
                        : 10
                      : 10 * (page + 1) > count
                      ? count
                      : 10 * (page + 1)
                  }/${count}`;
                }}
                showFirstButton={true}
                showLastButton={true}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            לאשר מחיקה לצמיתות?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}> ביטול </Button>
          <Button
            onClick={() => {
              handleDelete(selectedId);
              handleCloseDialog();
            }}
            autoFocus
          >
            מחק
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen
        open={openRefDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: "relative", bgcolor: "white", color: "primary" }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="primary"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* מציג קובץ PDF או Word */}
          {link.endsWith(".pdf") ||
          link.endsWith(".docx") ||
          link.endsWith(".doc") ? (
            <iframe
              src={link}
              width="100%"
              height="100%"
              style={{ border: "none" }}
            />
          ) : (
            <img
              src={link}
              alt="תמונה"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          )}
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
