import { createSelector } from "reselect";

export const sliceSelector = (state) => state.applications;
export const isLoading = createSelector(
  sliceSelector,
  (slice) => slice.loading
);
export const error = createSelector(sliceSelector, (slice) => slice.error);
export const submitSuccess = createSelector(
  sliceSelector,
  (slice) => slice.submitSuccess
);
export const applications = createSelector(
  sliceSelector,
  (slice) => slice.applications
);
export const filter = createSelector(sliceSelector, (slice) => slice.filter);
export const application = createSelector(
  sliceSelector,
  (slice) => slice.application
);
export const isSubmitting = createSelector(
  sliceSelector,
  (slice) => slice.isSubmitting
);
