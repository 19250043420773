import { connect } from "../../utils/reduxUtils";
import Component from "./DataTable";
import * as ExpensesSelector from "../../modules/expenses/selectors";
import { Actions } from "../../pages/actions";

const mapDispatchToProps = {
  onUpdate: Actions.UPDATE_EXPENSE_STATUS,
  saveFile: Actions.SAVE_FILE,
};

const mapStateToProps = (state) => {
  return {
    isSubmitting: ExpensesSelector.isSubmitting(state),
    error: ExpensesSelector.error(state),
    submitSuccess: ExpensesSelector.submitSuccess(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
