import { put, call, takeLatest } from "redux-saga/effects";
import { Sagas } from ".";
import {
  ActionsTypes as categotyActionTypes,
  Actions as referencesActions,
} from "./actions";
import { ActionTypes as PagesActions } from "../../pages/actions";
import axios from "../../services/axios";

function* getReferences(action) {
  console.log("in sagaaaaaaaaa");
  let { payload } = action;
  try {
    yield put(referencesActions.GET_REFERENCES_REQUEST());
    const { data } = yield call(
      axios.get,
      `/export-batch?page=${payload.page}`
    );
    console.log("data is: ", data);
    yield put(referencesActions.GET_REFERENCES_SUCCESS(data));
  } catch (err) {
    yield put(referencesActions.GET_REFERENCES_FAILURE());
  }
}

function* saveFaile(action) {
  console.log("in saveFaile");
  let { payload } = action;
  const formData = new FormData();
  console.log("payload.file.file", payload.file.file);
  formData.append("file", payload.file.file, payload.file.name);
  console.log("formDataaaaaa", payload);
  try {
    const { data } = yield call(
      axios.put,
      `/export-batch/${payload.rowId}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    // const response = yield axios.put(`/export-batch/${payload.rowId}`, {
    //   method: "POST",
    //   body: formData,
    // });
    yield put(referencesActions.SAVE_FILE_SUCCESS(data));
    console.log("File saved successfully!", data);
  } catch (error) {
    yield put(referencesActions.SAVE_FILE_FAILURE(error));
    console.error("Error saving file:", error);
  }
}

const sagas = [
  takeLatest(PagesActions.GET_REFERENCES, getReferences),
  takeLatest(PagesActions.SAVE_FILE, saveFaile),
];
export default sagas;
