import {
  createActionCreators,
  createActionTypes,
} from "../../utils/reduxUtils";

const typesActions = [
  "GET_REFERENCES_REQUEST",
  "GET_REFERENCES_SUCCESS",
  "GET_REFERENCES_FAILURE",

  "SAVE_FILE_REQUEST",
  "SAVE_FILE_SUCCESS",
  "SAVE_FILE_FAILURE",
];
export const Actions = createActionCreators(typesActions);
export const ActionsTypes = createActionTypes(typesActions);
