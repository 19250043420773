import { connect } from "../../utils/reduxUtils";
import Component from "./References";
import { Actions } from "../../pages/actions";
import * as ReferencesSelector from "../../modules/refernces/selectors";

const mapDispatchToProps = {
  getReferences: Actions.GET_REFERENCES,
};

const mapStateToProps = (state) => {
  return {
    loading: ReferencesSelector.isLoading(state),
    data: ReferencesSelector.data(state),
    saveSuccess: ReferencesSelector.saveSuccess(state),
    isSaving: ReferencesSelector.isSaving(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
