import Layout from "../layout/Layout";
import Error from "../../pages/error";
import Login from "../../pages/login";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import React from "react";
import PrivateFundForm from "../../pages/privateFundForm";
import ForgotPassword from "../../pages/forgotPass";
import ResetPassword from "../../pages/resetPassword";
import InstituteFundForm from "../../pages/instituteFundForm";
import References from "../../pages/references/References";

export default function App({ isAuthenticated, isAuthComplete }) {
  const PrivateRoute = ({ component }) => {
    const location = useLocation();
    return isAuthenticated ? (
      React.createElement(component)
    ) : (
      <Navigate
        to={{
          pathname: "/login",
          state: {
            from: location,
          },
        }}
      />
    );
  };

  return (
    <>
      <Router>
        <Routes>
          <Route path="*" element={<PrivateRoute component={Layout} />} />
          <Route path="/" element={<PrivateFundForm />} />
          <Route path="/mosad" element={<InstituteFundForm />} />
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="/*" element={<Error />} />
        </Routes>
      </Router>
    </>
  );
}
