import { createSelector } from "reselect";

export const sliceSelector = (state) => state.refernces;
export const isLoading = createSelector(
  sliceSelector,
  (slice) => slice.loading
);
export const data = createSelector(sliceSelector, (slice) => slice.data);
export const saveSuccess = createSelector(
  sliceSelector,
  (slice) => slice.saveSuccess
);
export const isSaving = createSelector(
  sliceSelector,
  (slice) => slice.isSaving
);
