import DataTable from "../../components/DataTable";
import { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Alert,
  Snackbar,
  CircularProgress,
  Box,
} from "@mui/material";
import { useParams } from "react-router-dom";

export default function References({
  loading,
  getReferences,
  data,
  saveSuccess,
}) {
  const [page, setPage] = useState(0);
  const [sortedTable, setsortedTable] = useState(data);
  const [open, setOpen] = useState(false);
  const [dataView, setDataView] = useState([]);
  const [openRefDialog, setOpenRefDialog] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { id } = useParams();
  const onChangePagination = (newPage) => {
    getReferences({ page: newPage });
    setPage(newPage);
  };
  useEffect(() => {
    console.log("in use efect");
    getReferences({ page });
  }, []);
  useEffect(() => {
    console.log("in use efect save success");
    if (saveSuccess === true) {
      getReferences({ page });
    }
  }, [saveSuccess]);

  useEffect(() => {
    setsortedTable(data);
    console.log("ddddddddddddddddd", data);
  }, [data]);

  const showData = (data) => {
    // console.log("in showwwwwwwww dataaaa");
    // let showtable = [];
    // let date;
    // let ddate;

    // if (data.length > 0) {
    //   data.map((item, index) => {
    //     date = new Date(item.createdAt);
    //     console.log("sssssss", date);
    //     ddate =
    //       date.getDay() +
    //       "." +
    //       (date.getMonth() + 1) +
    //       "." +
    //       date.getFullYear();
    //     showtable.push({ createdAt: ddate });
    //   });
    // }
    return data;
  };
  // let goodm = showData(refernncesData);
  // console.log("goooooood", goodm);
  let headers = [
    {
      name: "תאריך",
      width: "15%",
      table: "createdAt",
    },
    {
      name: "אסמכתא",
      width: "15%",
      table: "refernce",
    },
    {
      name: "הצגת קובץ",
      width: "15%",
      table: "showFile",
    },
  ];

  return (
    <>
      <Container
        maxWidth="md"
        sx={{
          width: "90%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{ mb: 10, pt: 5, fontSize: "1.3rem", fontWeight: "bold" }}
        >
          אסמכתאות הוצאה למס"ב
        </Typography>
        {loading ? (
          <CircularProgress
            color="primary"
            sx={{ position: "absolute", top: "50%", left: "50%" }}
          />
        ) : (
          <DataTable
            fdata={sortedTable}
            headers={headers}
            page={page}
            showData={showData}
            disableClick={true}
            setOpen={setOpen}
            onChange={onChangePagination}
            setOpenRefDialog={setOpenRefDialog}
            setIsSaving={setIsSaving}
          />
        )}
      </Container>
    </>
  );
}
